import * as React from "react"

function TaglineMobile(props) {
  return (
    <svg
      style={{
        position: `absolute`,
      }}
      xmlns="http://www.w3.org/2000/svg"
      // width={206.89}
      // height={198.67}
      viewBox="0 0 206.89 198.67"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path
            d="m156.17 120.8 2.05 2.06a1.93 1.93 0 0 0-.35-.3l-2.05-2.06c.12.09.23.19.35.3Z"
            fill="none"
          />
        </clipPath>
        <clipPath id="b">
          <path
            d="m155.83 120.46 2 2.06a1.93 1.93 0 0 0-.35-.3l-2.05-2.06a3.27 3.27 0 0 1 .4.3Z"
            fill="none"
          />
        </clipPath>
        <clipPath id="c">
          <path
            d="m155.15 119.78 2.06 2.06a2.38 2.38 0 0 0-.36-.3l-2-2.05a2.34 2.34 0 0 1 .3.29Z"
            fill="none"
          />
        </clipPath>
      </defs>
      <path d="M10.65 50.55a52 52 0 0 1 8.7.81V60c-1.36-2.44-2.74-4.12-5.81-4.12a4.37 4.37 0 0 0-4.4 3.26c-.74 2.14-.82 8.26-.82 14.17 0 5.41-.3 13.23 5.59 13.23a6.64 6.64 0 0 0 5.24-2.8v7.15a27.59 27.59 0 0 1-6.65.82c-3.14 0-6.63-.8-8.6-3.07C.81 85.08 0 78.66 0 73.35c0-11.62 1.21-22.8 10.65-22.8Z" />
      <path
        d="M12.51 90.5c-3.41 0-6.21-1-7.67-2.65-2.32-2.68-3.6-7.85-3.6-14.49 0-4.86.19-11 1.71-15.36s3.93-6.22 7.71-6.22a49.41 49.41 0 0 1 7.47.63v3.89a6.56 6.56 0 0 0-4.58-1.64A5.6 5.6 0 0 0 8 58.75c-.74 2.12-.89 7.15-.89 14.57v.75c0 4.2 0 9.42 2.62 12.07a5.71 5.71 0 0 0 4.2 1.64 6.93 6.93 0 0 0 4-1.23v3.37a25.77 25.77 0 0 1-5.42.58Z"
        fill="#c51f30"
      />
      <path d="M34 50.91v39.94h-7.8V54.31a6.35 6.35 0 0 0-1-3.4Z" />
      <path
        d="M27.44 89.63V54.32a6.44 6.44 0 0 0-.31-2.17h5.69v37.48Z"
        fill="#c51f30"
      />
      <path
        d="M168.72 85.9a3.65 3.65 0 0 0 .71 1.16l2.06 2-2.06-2a3.65 3.65 0 0 1-.71-1.16ZM167.79 81.52c0-.39-.07-.78-.1-1.18.03.4.06.79.1 1.18ZM167.93 82.65ZM168.57 85.52c-.09-.26-.17-.55-.25-.85.08.33.16.59.25.85Z"
        fill="none"
      />
      <path
        d="M168.38 85.57a3.54 3.54 0 0 0 .72 1.15l2.05 2-2.05-2a3.54 3.54 0 0 1-.72-1.15ZM167.45 81.18c0-.39-.07-.78-.1-1.18.03.4.06.79.1 1.18ZM167.59 82.31ZM168.24 85.18a8.54 8.54 0 0 1-.26-.85 8.54 8.54 0 0 0 .26.85Z"
        fill="none"
      />
      <path
        d="M167.7 84.89a3.6 3.6 0 0 0 .72 1.15l2.05 2.06-2.05-2.1a3.6 3.6 0 0 1-.72-1.11ZM166.78 80.5c0-.38-.08-.78-.11-1.18.03.4.07.8.11 1.18ZM166.91 81.63c0-.37-.09-.74-.13-1.13.04.39.08.76.13 1.13ZM167.56 84.51a8.76 8.76 0 0 1-.26-.86 8.76 8.76 0 0 0 .26.86Z"
        fill="none"
      />
      <path d="M169.12 50.55c9.6 0 11.39 9.57 11.39 19.87 0 11.4-1.77 21.14-11.14 21.14-9.53 0-11.1-8.89-11.09-20.81 0-9.75 1.62-20.2 10.84-20.2Zm.29 35.94c3.56 0 2.89-11.37 2.89-15.82 0-5.48.17-14.92-2.9-14.92s-2.91 9-2.91 14.25c0 4.6-.34 16.49 2.92 16.49" />
      <path
        d="M169.38 90.35c-3.94 0-6.48-1.65-8-5.19-1.66-3.91-1.88-9.75-1.88-14.4 0-13 3.06-19 9.61-19a8.17 8.17 0 0 1 8.18 5.35c1.36 3 2 7.26 2 13.3 0 13.98-2.96 19.94-9.91 19.94Zm0-35.82a2.75 2.75 0 0 0-2 .85c-2 2-2.17 7.45-2.16 13.82v2c0 6.74.12 13.52 2.3 15.71a2.58 2.58 0 0 0 1.86.81 2.81 2.81 0 0 0 2-.89c2.11-2.14 2.2-8 2.1-14.26v-2.71c0-6.72-.15-12.45-2.2-14.51a2.74 2.74 0 0 0-1.88-.82Z"
        fill="#c51f30"
      />
      <path d="m110.24 50.91 7.19 40H109l-1.67-10.3h-6c-.15 1.18-1.3 6.78-1.3 8a5 5 0 0 0 .67 2.31H94L100.62 56a13.54 13.54 0 0 0 .44-3.2 3.9 3.9 0 0 0-.55-1.92Zm-8.07 24.27h4.31l-1.87-12.58-2.44 12.58" />
      <path
        d="m110.06 89.66-1.51-9.26a1.22 1.22 0 0 0-1.21-1h-6a1.23 1.23 0 0 0-1.22 1.07c0 .38-.23 1.37-.42 2.43a50 50 0 0 0-.89 5.71 5.17 5.17 0 0 0 .12 1.08h-3.46l6.36-33.39v-.19a13 13 0 0 0 .42-3.33 5.54 5.54 0 0 0-.06-.6h7L116 89.66Zm-5.45-28.28a1.23 1.23 0 0 0-1.2 1L101 75a1.26 1.26 0 0 0 .26 1 1.23 1.23 0 0 0 1 .44h4.31A1.21 1.21 0 0 0 107.7 75l-1.87-12.58a1.24 1.24 0 0 0-1.19-1Z"
        fill="#c51f30"
      />
      <path d="M151.18 93a12.68 12.68 0 0 0 1.24 5.68 6.94 6.94 0 0 1-1 .07 7.55 7.55 0 0 1-3.18-.67 22.26 22.26 0 0 0-3-1.58 3.14 3.14 0 0 0-1.05-.15 6.61 6.61 0 0 0-1.69.23 9.16 9.16 0 0 0 .85-4V63.1L136 88.15l-7.8-24.23c0 1.18.1 23.52.1 24.49a5.62 5.62 0 0 0 .73 2.47h-6.3V53.94a7.54 7.54 0 0 0-.86-3h9.71L137 67.8l5.1-16.89h9.18c-.03 1.86-.1 37.09-.1 42.09Z" />
      <path
        d="M150.62 97.48a6 6 0 0 1-1.86-.5 12.45 12.45 0 0 1-1.09-.62 9.72 9.72 0 0 0-2.07-1 4.79 4.79 0 0 0-1.34-.2 11.31 11.31 0 0 0 .28-2.57V63.11a1.23 1.23 0 0 0-1.06-1.22h-.17a1.24 1.24 0 0 0-1.18.89L136 84l-6.57-20.39a1.24 1.24 0 0 0-1.17-.86h-.2a1.22 1.22 0 0 0-1 1.22c0 .23.11 23.53.11 24.48a5 5 0 0 0 .16 1.25H124V53.97a6 6 0 0 0-.29-1.79h7l5.09 16a1.22 1.22 0 0 0 1.2.82 1.22 1.22 0 0 0 1.16-.87l4.83-16h7c0 6.47-.05 36.4-.05 40.86a15.38 15.38 0 0 0 .68 4.49Z"
        fill="#c51f30"
      />
      <path d="M88.61 50.91v39.87H82l-7.8-25.3v18.25c0 2.65-.27 4.7 1 7h-6.68V54.57a6.23 6.23 0 0 0-1-3.66h8.92l6.46 18.34V50.91Z" />
      <path
        d="m82.9 89.56-7.52-24.44a1.22 1.22 0 0 0-1.17-.86H74a1.23 1.23 0 0 0-1 1.22v19.41a15.06 15.06 0 0 0 .41 4.66h-3.65v-35a7.61 7.61 0 0 0-.31-2.42h6.14l6.16 17.52a1.24 1.24 0 0 0 1.16.82.75.75 0 0 0 .21 0 1.22 1.22 0 0 0 1-1.21V52.15h3.25v37.41Z"
        fill="#c51f30"
      />
      <path d="M206.89 50.91v39.87h-6.61l-7.78-25.3v18.25c0 2.65-.27 4.7 1 7h-6.63V54.52a6.16 6.16 0 0 0-1-3.66h8.93l6.44 18.34V50.91Z" />
      <path
        d="m201.18 89.56-7.52-24.44a1.22 1.22 0 0 0-1.17-.86h-.19a1.23 1.23 0 0 0-1 1.22v19.41a15.06 15.06 0 0 0 .41 4.66H188v-35a7.3 7.3 0 0 0-.32-2.42h6.15L200 69.67a1.22 1.22 0 0 0 1.16.82.68.68 0 0 0 .2 0 1.23 1.23 0 0 0 1-1.21V52.15h3.24v37.41Z"
        fill="#c51f30"
      />
      <path d="M61.37 50.91v39.87h-6.63L47 65.48c0 2.64-.05 26.44 0 27.78a8.92 8.92 0 0 0 .75 3.43 3.84 3.84 0 0 0-.71-.06 4.92 4.92 0 0 0-1.67.28 15.15 15.15 0 0 0-2.15 1.23 4.62 4.62 0 0 1-2.19.62 4.91 4.91 0 0 1-.55 0 14.1 14.1 0 0 0 .88-5.08V54.61a6.23 6.23 0 0 0-1-3.66h8.92l6.44 18.34V50.91Z" />
      <path
        d="M42.14 97.28a17.86 17.86 0 0 0 .39-3.63V54.57a7.33 7.33 0 0 0-.31-2.42h6.14l6.16 17.52a1.24 1.24 0 0 0 1.16.82.75.75 0 0 0 .21 0 1.22 1.22 0 0 0 1-1.21V52.15h3.25v37.41h-4.48l-7.52-24.44a1.22 1.22 0 0 0-1.14-.86h-.18a1.23 1.23 0 0 0-1 1.22v27.79a10.12 10.12 0 0 0 .18 2.22 5.54 5.54 0 0 0-1.1.28 7.19 7.19 0 0 0-1.58.85 7.82 7.82 0 0 1-.72.45Z"
        fill="#c51f30"
      />
      <path d="M34.27 0A51 51 0 0 1 43 .82v8.63C41.61 7 40.23 5.33 37.16 5.33a4.37 4.37 0 0 0-4.4 3.26c-.74 2.14-.82 8.26-.82 14.17 0 5.41-.3 13.23 5.59 13.23a6.64 6.64 0 0 0 5.24-2.8v7.15a27.59 27.59 0 0 1-6.65.82c-3.14 0-6.63-.8-8.6-3.07-3.09-3.56-3.9-10-3.9-15.29C23.62 11.18 24.83 0 34.27 0Z" />
      <path
        d="M36.13 40c-3.41 0-6.21-1-7.67-2.65-2.32-2.68-3.6-7.82-3.6-14.49 0-4.86.19-11 1.71-15.36s3.93-6.22 7.71-6.22a49.41 49.41 0 0 1 7.47.63v3.84a6.56 6.56 0 0 0-4.58-1.64 5.6 5.6 0 0 0-5.56 4.09c-.74 2.12-.89 7.15-.89 14.57v.75c0 4.2 0 9.42 2.62 12.07a5.71 5.71 0 0 0 4.2 1.64 6.93 6.93 0 0 0 4-1.23v3.37a25.77 25.77 0 0 1-5.41.63Z"
        fill="#c51f30"
      />
      <path d="M174.57 0a51.19 51.19 0 0 1 8.7.82v8.63c-1.36-2.44-2.73-4.12-5.81-4.12a4.35 4.35 0 0 0-4.39 3.26c-.75 2.14-.82 8.26-.82 14.17 0 5.41-.31 13.23 5.58 13.23 2.29 0 3.84-1.15 5.25-2.8v7.15a27.61 27.61 0 0 1-6.66.82c-3.14 0-6.63-.8-8.6-3.07-3.09-3.56-3.9-10-3.9-15.29 0-11.62 1.2-22.8 10.65-22.8Z" />
      <path
        d="M176.43 40c-3.42 0-6.21-1-7.68-2.65-2.32-2.68-3.6-7.82-3.6-14.49 0-4.86.2-11 1.71-15.36s3.93-6.22 7.71-6.22a49.3 49.3 0 0 1 7.47.63v3.84a6.56 6.56 0 0 0-4.58-1.64 5.61 5.61 0 0 0-5.56 4.09c-.73 2.12-.88 7.15-.88 14.57v.75c0 4.2 0 9.42 2.62 12.07a5.67 5.67 0 0 0 4.19 1.64 7 7 0 0 0 4-1.23v3.37a25.84 25.84 0 0 1-5.4.63Z"
        fill="#c51f30"
      />
      <path d="M157 .73v39.93h-7.83V4.13a6.33 6.33 0 0 0-1-3.4Z" />
      <path
        d="M150.36 39.45V4.14a6.66 6.66 0 0 0-.31-2.14h5.69v37.45Z"
        fill="#c51f30"
      />
      <path d="M57.83.73v34.68h4c2.06 0 2.4-.76 3.83-2.31v7.47H50V4.68a6.9 6.9 0 0 0-1-3.95Z" />
      <path
        d="M51.23 39.36V4.68A8.39 8.39 0 0 0 50.89 2h5.71v33.42a1.23 1.23 0 0 0 1.23 1.23h4A4.78 4.78 0 0 0 64.4 36v3.32Z"
        fill="#c51f30"
      />
      <path d="M116.65.73V8.5c-2.1-2.65-3.19-2.65-6.26-2.65-2.25 0-2.81.88-2.81 3.44 0 3.36 2.66 3.86 4.94 5.23 3.13 1.87 5 6.12 5.28 10.94.41 7.81-.55 15.07-6.71 15.07h-10.51l-.18-8.31c2.4 2.39 3.52 3 6 3 3.1 0 3.67-3.76 3.68-8.61 0-3.78-1.86-6.15-4.43-7.21-3.84-1.59-6.13-4.71-6.13-10 0-5.67 2.31-8.61 6.73-8.61Z" />
      <path
        d="m101.8 39.31-.1-4.31a7.11 7.11 0 0 0 4.67 1.42c4.91 0 4.91-6.65 4.91-9.84 0-3.94-1.89-7-5.19-8.34-3.62-1.5-5.38-4.42-5.38-8.92 0-2.53.49-4.44 1.45-5.67a4.81 4.81 0 0 1 4-1.7h9.22V5.6c-1.47-1-2.89-1-5-1-3.55 0-4 2.25-4 4.67 0 3.44 2.34 4.62 4.22 5.57.45.23.91.46 1.33.71 2.66 1.59 4.45 5.41 4.69 10 .34 6.47-.39 10.55-2.23 12.49a4.23 4.23 0 0 1-3.26 1.29Z"
        fill="#c51f30"
      />
      <path d="M140.91.73V8.5c-2.1-2.65-3.19-2.65-6.26-2.65-2.25 0-2.81.88-2.81 3.44 0 3.36 2.66 3.86 4.94 5.23 3.13 1.87 5 6.12 5.29 10.94.4 7.81-.56 15.07-6.72 15.07h-10.5l-.19-8.31c2.4 2.39 3.52 3 6 3 3.1 0 3.68-3.76 3.68-8.61 0-3.78-1.86-6.15-4.42-7.21-3.85-1.59-6.14-4.71-6.14-10 0-5.67 2.31-8.61 6.73-8.61Z" />
      <path
        d="M126.06 39.31 126 35a7.07 7.07 0 0 0 4.67 1.42c4.9 0 4.9-6.65 4.9-9.84 0-3.94-1.89-7-5.18-8.34-3.63-1.5-5.39-4.42-5.39-8.92 0-2.53.49-4.44 1.45-5.67A4.82 4.82 0 0 1 130.48 2h9.21v3.6c-1.47-1-2.89-1-5-1-3.55 0-4 2.25-4 4.67 0 3.44 2.34 4.62 4.22 5.57.44.23.9.46 1.32.71 2.66 1.59 4.46 5.41 4.69 10 .34 6.47-.39 10.55-2.23 12.49a4.23 4.23 0 0 1-3.26 1.29Z"
        fill="#c51f30"
      />
      <path d="m87.21.73 7.19 40H86L84.3 30.4h-6c-.15 1.13-2 11.64-2.17 12.34a8.81 8.81 0 0 0 0 3.43 2.52 2.52 0 0 0-.79-.12 4 4 0 0 0-1.23.22 21 21 0 0 0-2.51 1.35 4.44 4.44 0 0 1-2 .51 3.84 3.84 0 0 1-.76-.07 15.56 15.56 0 0 0 1.7-4.94c.54-2.84 6.8-35.55 7.13-37.27A13.55 13.55 0 0 0 78 2.64a3.86 3.86 0 0 0-.52-1.91ZM79.14 25h4.31l-1.87-12.58L79.14 25" />
      <path
        d="M70.8 46.63a20.43 20.43 0 0 0 .88-3.27c.53-2.8 7-36.93 7.12-37.27V5.9a13.09 13.09 0 0 0 .42-3.33A5.54 5.54 0 0 0 79.2 2h7L93 39.48h-6l-1.51-9.27a1.23 1.23 0 0 0-1.21-1h-6a1.23 1.23 0 0 0-1.22 1.07c-.14 1.06-2 11.44-2.15 12.28a10.62 10.62 0 0 0-.2 2.35 4.94 4.94 0 0 0-1 .24 8.8 8.8 0 0 0-1.84.94c-.29.17-.57.34-.84.48ZM81.58 11.2a1.22 1.22 0 0 0-1.2 1l-2.44 12.57a1.24 1.24 0 0 0 1.21 1.47h4.31a1.23 1.23 0 0 0 1.21-1.41L82.8 12.24a1.22 1.22 0 0 0-1.19-1Z"
        fill="#c51f30"
      />
      <path d="M53.8 101.18v7.76c-2.1-2.64-3.19-2.64-6.26-2.64-2.25 0-2.81.88-2.81 3.44 0 3.35 2.66 3.86 4.94 5.23 3.13 1.87 5 6.12 5.28 10.94.41 7.81-.55 15.06-6.71 15.06H37.73l-.18-8.31c2.4 2.4 3.52 3 6 3 3.1 0 3.67-3.76 3.68-8.6 0-3.78-1.86-6.15-4.43-7.21-3.84-1.59-6.13-4.71-6.13-10 0-5.66 2.31-8.6 6.73-8.6Z" />
      <path
        d="m39 139.76-.1-4.29a7.16 7.16 0 0 0 4.67 1.41c4.91 0 4.91-6.64 4.91-9.83 0-3.94-1.89-7-5.19-8.35-3.62-1.5-5.38-4.41-5.38-8.91 0-2.54.49-4.44 1.45-5.67a4.8 4.8 0 0 1 4.06-1.71h9.21v3.64c-1.47-1-2.89-1-5-1-3.55 0-4 2.24-4 4.67 0 3.43 2.34 4.62 4.23 5.57.44.22.9.46 1.32.71 2.66 1.59 4.45 5.4 4.69 10 .34 6.47-.39 10.55-2.23 12.49a4.27 4.27 0 0 1-3.26 1.29Z"
        fill="#c51f30"
      />
      <path d="m132.68 101.18 7.19 40h-8.43l-1.68-10.29h-6c-.16 1.17-1.3 6.77-1.3 8a5 5 0 0 0 .67 2.31h-6.69l6.65-34.84a13.61 13.61 0 0 0 .44-3.21 3.86 3.86 0 0 0-.55-1.91Zm-8.07 24.26h4.31l-1.87-12.58-2.44 12.58" />
      <path
        d="m132.5 139.93-1.5-9.27a1.23 1.23 0 0 0-1.21-1h-6a1.23 1.23 0 0 0-1.22 1.07c0 .38-.23 1.38-.42 2.43a49.67 49.67 0 0 0-.89 5.72 5.33 5.33 0 0 0 .12 1.08h-3.42l6.36-33.4v-.19a13 13 0 0 0 .42-3.32 5.73 5.73 0 0 0-.06-.61h7l6.76 37.52Zm-5.45-28.29a1.23 1.23 0 0 0-1.2 1l-2.44 12.58a1.22 1.22 0 0 0 1.21 1.46h4.31a1.21 1.21 0 0 0 1.21-1.41l-1.87-12.58a1.24 1.24 0 0 0-1.19-1Z"
        fill="#c51f30"
      />
      <path d="M101.26 101.18a29.45 29.45 0 0 1 8.57 1v7.26c-2.53-2.37-3.73-2.77-7.14-2.77-4 0-5.31 5-5.32 10l-.08 6c0 5-.46 13.86 3.37 13.86a4 4 0 0 0 1.42-.21v-3.78c0-2.39.08-11.38-1-13.48h9.52v21.54c-2.85 1.22-7.61 1.59-10.65 1.59-8.65 0-11.25-6.93-11.24-17.21.06-13.5 1.94-23.8 12.55-23.8Z" />
      <path
        d="M100 140.93a9.9 9.9 0 0 1-4.82-1.09 7.7 7.7 0 0 1-3-3.05c-1.49-2.6-2.22-6.48-2.22-11.85 0-7.82.67-12.89 2.17-16.45 1.75-4.14 4.64-6.08 9.09-6.08a30.27 30.27 0 0 1 7.34.72v3.73c-1.65-1.11-3.19-1.41-5.91-1.41-4.16 0-6.55 4.09-6.55 11.22l-.08 6v1.45c0 4.61-.08 10.35 2.18 12.63a3.37 3.37 0 0 0 2.43 1 5.28 5.28 0 0 0 1.86-.29 1.24 1.24 0 0 0 .8-1.14v-3.77a76 76 0 0 0-.58-12.28h6.68v19.48a33.55 33.55 0 0 1-9.39 1.18Z"
        fill="#c51f30"
      />
      <path d="M81.62 101.18v36.35a5.68 5.68 0 0 0 .93 3.68H69c-7.5 0-7.28-3.19-7.27-10.29 0-3.86.08-18.41.08-22.27 0-2.77.26-5-1-7.47h8.81v31.59c0 2.6.86 2.94 3 2.94h1.16v-34.53Z" />
      <path
        d="M69 140c-3.8 0-4.82-.87-5.3-1.72-.75-1.3-.75-3.58-.75-6.73v-24.07a16.7 16.7 0 0 0-.44-5.06h5.77v30.37c0 3.47 1.62 4.17 4.23 4.17h1.16a1.23 1.23 0 0 0 1.33-1.24v-33.31h5.4v35.13a10 10 0 0 0 .24 2.45Z"
        fill="#c51f30"
      />
      <g
        clipPath="url(#a)"
        style={{
          isolation: "isolate",
        }}
      >
        <path
          d="m156.17 120.8 2.05 2.06a1.93 1.93 0 0 0-.35-.3l-2.05-2.06c.12.09.23.19.35.3"
          fill="#74919d"
        />
      </g>
      <g
        clipPath="url(#b)"
        style={{
          isolation: "isolate",
        }}
      >
        <path
          d="m155.83 120.46 2 2.06a1.93 1.93 0 0 0-.35-.3l-2.05-2.06a3.27 3.27 0 0 1 .35.3"
          fill="#5e7079"
        />
      </g>
      <g
        clipPath="url(#c)"
        style={{
          isolation: "isolate",
        }}
      >
        <path
          d="m155.15 119.78 2.06 2.06a2.38 2.38 0 0 0-.36-.3l-2-2.05a2.34 2.34 0 0 1 .35.29"
          fill="#495b64"
        />
      </g>
      <path d="M167.83 142.58a14.09 14.09 0 0 0 2.43 5.89 7.27 7.27 0 0 1-1 .07 8.22 8.22 0 0 1-3.27-.7c-1.34-.58-1.72-1.21-3.12-1.64a4.09 4.09 0 0 0-1.21-.15 7.54 7.54 0 0 0-1.93.24 10.28 10.28 0 0 0 .47-4.17 141 141 0 0 0-2.3-15.51c-.68-3.29-1.76-6.22-3.06-7.12V141H147v-36.38a6.2 6.2 0 0 0-1-3.44h12.06c5.7 0 8 2.66 8.22 8.74.1 3.06-.85 6.56-4.31 7.72 1.45 1.25 2.43 4.78 3.15 8.24 1.41 6.75 2.09 12.5 2.71 16.7ZM155 113.73c2.5 0 3.4-1 3.25-3.61-.14-2.48-.81-4-2.69-4h-.71v7.55h.15" />
      <path
        d="M168.08 147.22a7.15 7.15 0 0 1-1.62-.49 7.77 7.77 0 0 1-1.2-.68 7.21 7.21 0 0 0-2-1 5.52 5.52 0 0 0-1.58-.2h-.32a11.09 11.09 0 0 0 .09-2.82 141.62 141.62 0 0 0-2.33-15.65c-.6-2.88-1.7-6.59-3.56-7.88a1.21 1.21 0 0 0-.7-.22 1.15 1.15 0 0 0-.57.14 1.22 1.22 0 0 0-.66 1.09v20.3h-5.43v-35.19a7.33 7.33 0 0 0-.3-2.21h10.16c4.92 0 6.81 2 7 7.56.15 4.6-2.11 6.06-3.47 6.51a1.22 1.22 0 0 0-.81.93 1.19 1.19 0 0 0 .4 1.16c.95.83 1.87 3.38 2.75 7.57 1.09 5.25 1.75 9.92 2.28 13.68l.42 3a15.25 15.25 0 0 0 1.45 4.4ZM154.81 105a1.23 1.23 0 0 0-1.23 1.23v7.55a1.23 1.23 0 0 0 1.21 1.23h.21a4.67 4.67 0 0 0 3.48-1.16 5 5 0 0 0 1-3.75c-.19-3.38-1.51-5.1-3.92-5.1Z"
        fill="#c51f30"
      />
      <path d="M111.58 151v7.77c-2.1-2.65-3.18-2.65-6.26-2.65-2.25 0-2.81.88-2.81 3.44 0 3.36 2.66 3.86 4.95 5.23 3.12 1.87 5 6.12 5.28 10.94.4 7.81-.56 15.07-6.72 15.07h-10.5l-.19-8.31c2.4 2.39 3.52 3 6 3 3.11 0 3.68-3.76 3.68-8.61 0-3.78-1.86-6.15-4.42-7.21-3.84-1.59-6.14-4.71-6.14-10 0-5.67 2.32-8.61 6.74-8.61Z" />
      <path
        d="m96.73 189.54-.09-4.29a7.09 7.09 0 0 0 4.67 1.42c4.9 0 4.9-6.65 4.9-9.84 0-3.94-1.89-7-5.18-8.34-3.62-1.5-5.39-4.42-5.39-8.91 0-2.54.49-4.45 1.45-5.68a4.82 4.82 0 0 1 4.06-1.7h9.21v3.63c-1.46-1-2.89-1-5-1-3.55 0-4 2.25-4 4.67 0 3.44 2.34 4.62 4.22 5.57.44.23.9.46 1.32.71 2.66 1.59 4.46 5.41 4.69 9.95.34 6.47-.39 10.55-2.23 12.49a4.23 4.23 0 0 1-3.25 1.29Z"
        fill="#c51f30"
      />
      <path d="m82.9 151 7.19 40h-8.43L80 180.63h-6c-.15 1.17-1.3 6.77-1.3 8a4.93 4.93 0 0 0 .68 2.31h-6.7l6.65-34.85a13.63 13.63 0 0 0 .45-3.2 3.87 3.87 0 0 0-.55-1.92Zm-8.08 24.27h4.31l-1.87-12.58-2.44 12.58" />
      <path
        d="m82.72 189.71-1.51-9.27a1.24 1.24 0 0 0-1.22-1H74a1.22 1.22 0 0 0-1.21 1.08c0 .38-.24 1.37-.43 2.42a51.22 51.22 0 0 0-.88 5.72 5.33 5.33 0 0 0 .12 1.08h-3.48l6.36-33.39v-.19a13.15 13.15 0 0 0 .52-3.36c0-.21 0-.41-.07-.6h7l6.75 37.51Zm-5.45-28.28a1.23 1.23 0 0 0-1.21 1L73.63 175a1.19 1.19 0 0 0 .26 1 1.23 1.23 0 0 0 .94.45h4.31a1.24 1.24 0 0 0 1.22-1.41l-1.87-12.58a1.22 1.22 0 0 0-1.19-1Z"
        fill="#c51f30"
      />
      <path d="M164.15 151v7.39c-2.08-2.29-2.52-2.4-5-2.4h-.79c-1.35 0-2.67.05-4 .05v6.64h8.36v5.45h-8.32v17.65h6c2.06 0 2.4-.75 3.83-2.3v7.46h-17.68v-36.2a6.09 6.09 0 0 0-1-3.74Z" />
      <path
        d="M147.8 189.69v-35a7.58 7.58 0 0 0-.3-2.5h15.43v3.32c-1-.7-2-.79-3.75-.79h-4.74a1.23 1.23 0 0 0-1.23 1.22v6.64a1.23 1.23 0 0 0 1.23 1.23h7.13v3h-7.13a1.23 1.23 0 0 0-1.23 1.23v17.66a1.23 1.23 0 0 0 1.23 1.22h6a4.69 4.69 0 0 0 2.59-.61v3.32Z"
        fill="#c51f30"
      />
      <path d="M138.61 151v9.21c-1.39-2.38-2.41-3.58-4.31-3.58h-1.57v31.08a6.61 6.61 0 0 0 .93 3.28h-8.81v-34.4h-1.61c-1.65 0-2.85 1.27-4 3.49V151Z" />
      <path
        d="M126.09 189.73v-33.14a1.23 1.23 0 0 0-1.23-1.22h-1.61a4.31 4.31 0 0 0-2.81 1v-4.17h16.95v4.3a4.51 4.51 0 0 0-3.08-1.13h-1.58a1.22 1.22 0 0 0-1.22 1.22v31.1a6.64 6.64 0 0 0 .3 2Z"
        fill="#c51f30"
      />
      <path d="M62.08 151v9.21c-1.39-2.38-2.41-3.58-4.31-3.58h-1.58v36.33a12.64 12.64 0 0 0 1.24 5.67 6.93 6.93 0 0 1-1 .08 7.55 7.55 0 0 1-3.18-.68 23.45 23.45 0 0 0-3-1.58 3.42 3.42 0 0 0-1-.14 6.74 6.74 0 0 0-1.69.22 9.13 9.13 0 0 0 .85-4v-35.94H46.7c-1.65 0-2.86 1.27-4 3.49V151Z" />
      <path
        d="M55.62 197.41a6 6 0 0 1-1.86-.51 11.14 11.14 0 0 1-1.09-.62 9.28 9.28 0 0 0-2.07-1 4.43 4.43 0 0 0-1.34-.21 11.22 11.22 0 0 0 .28-2.56v-35.92a1.23 1.23 0 0 0-1.23-1.22h-1.6a4.31 4.31 0 0 0-2.81 1v-4.17h17v4.3a4.51 4.51 0 0 0-3.08-1.13h-1.63a1.23 1.23 0 0 0-1.19 1.22v36.34a15.4 15.4 0 0 0 .62 4.48Z"
        fill="#c51f30"
      />
    </svg>
  )
}

export default TaglineMobile
