import * as React from "react"

function TaglineDesktop(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width={1194.53}
      // height={264.54}
      viewBox="0 0 1194.53 264.54"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path
            d="m327.19 187.68 5.62 5.62a7.16 7.16 0 0 0-1-.82l-5.62-5.62a7.16 7.16 0 0 1 1 .82Z"
            fill="none"
          />
        </clipPath>
        <clipPath id="b">
          <path
            d="m326.26 186.75 5.62 5.62a7.77 7.77 0 0 0-1-.82l-5.62-5.62a7.77 7.77 0 0 1 1 .82Z"
            fill="none"
          />
        </clipPath>
        <clipPath id="c">
          <path
            d="m324.41 184.89 5.61 5.62a7.11 7.11 0 0 0-1-.81l-5.62-5.62a7.86 7.86 0 0 1 1.01.81Z"
            fill="none"
          />
        </clipPath>
      </defs>
      <path d="M615.92 0a141.88 141.88 0 0 1 23.81 2.23v23.64c-3.72-6.7-7.49-11.28-15.9-11.28-5.64 0-9.9 2.76-12 8.93-2 5.85-2.24 22.6-2.24 38.77 0 14.82-.83 36.21 15.28 36.21 6.26 0 10.52-3.15 14.37-7.67v19.57a74.88 74.88 0 0 1-18.24 2.26c-8.6 0-18.15-2.2-23.54-8.41-8.45-9.74-10.67-27.32-10.67-41.84C586.77 30.59 590.06 0 615.92 0Z" />
      <path
        d="M621 109.34c-9.35 0-17-2.64-21-7.25-6.36-7.33-9.86-21.41-9.86-39.65 0-13.32.53-30.12 4.68-42 4-11.61 10.76-17 21.1-17a134 134 0 0 1 20.46 1.66v10.65a18 18 0 0 0-12.55-4.5c-7.3 0-12.7 4-15.2 11.19-2 5.8-2.42 19.56-2.42 39.88v2.05c0 11.49-.06 25.79 7.18 33.05a15.59 15.59 0 0 0 11.48 4.48 19.18 19.18 0 0 0 11-3.36v9.22a71.1 71.1 0 0 1-14.87 1.58Z"
        fill="#c51f30"
      />
      <path d="M679.94 1v109.29h-21.48v-100c0-4.25-.92-5.62-2.66-9.3Z" />
      <path
        d="M661.87 107V10.32a17.74 17.74 0 0 0-.87-5.94h15.58V107Z"
        fill="#c51f30"
      />
      <path
        d="M1048.55 96.77a9.66 9.66 0 0 0 2 3.15l5.62 5.62-5.62-5.62a9.66 9.66 0 0 1-2-3.15ZM1046 84.76c-.11-1.05-.21-2.13-.29-3.22.09 1.09.19 2.17.29 3.22ZM1046.39 87.85ZM1048.15 95.72Z"
        fill="none"
      />
      <path
        d="M1047.62 95.84a9.83 9.83 0 0 0 2 3.16l5.61 5.62-5.65-5.62a9.83 9.83 0 0 1-1.96-3.16ZM1045.08 83.84c-.11-1.06-.2-2.14-.29-3.23.09 1.09.21 2.17.29 3.23ZM1045.46 86.92ZM1047.22 94.8c-.25-.73-.49-1.51-.7-2.35.21.84.48 1.62.7 2.35Z"
        fill="none"
      />
      <path
        d="M1045.77 94a9.71 9.71 0 0 0 2 3.16l5.62 5.62-5.62-5.62a9.71 9.71 0 0 1-2-3.16ZM1043.23 82c-.11-1-.21-2.13-.29-3.22.06 1.07.18 2.15.29 3.22ZM1043.61 85.06a74.022 74.022 0 0 0 0 0ZM1045.37 92.94Z"
        fill="none"
      />
      <path d="M1049.64 0c26.27 0 31.19 26.2 31.18 54.38 0 31.21-4.85 57.87-30.5 57.87-26.06 0-30.36-24.35-30.36-57C1020 28.52 1024.4 0 1049.64 0Zm.79 98.36c9.75 0 7.91-31.1 7.91-43.29 0-15 .48-40.83-7.94-40.83s-8 24.76-8 39c0 12.6-.94 45.13 8 45.13" />
      <path
        d="M1050.36 108.93c-10.78 0-17.73-4.52-21.87-14.21-4.56-10.69-5.14-26.67-5.14-39.4 0-35.44 8.36-51.94 26.3-51.94 10.8 0 17.91 4.65 22.38 14.64 3.72 8.3 5.46 19.86 5.46 36.39 0 38.21-8.12 54.52-27.13 54.52Zm.06-98a7.51 7.51 0 0 0-5.39 2.31c-5.53 5.54-6 20.41-5.93 37.84v5.48c-.09 18.48.31 37 6.27 43a7.12 7.12 0 0 0 5.11 2.21 7.7 7.7 0 0 0 5.59-2.42c5.76-5.85 6-21.81 5.73-39 0-2-.06-3.73-.06-5.2v-2.28c0-18.39-.4-34.07-6-39.7a7.38 7.38 0 0 0-5.32-2.27Z"
        fill="#c51f30"
      />
      <path d="m888.49 1 19.68 109.38H885.1l-4.59-28.17H864c-.41 3.21-3.55 18.53-3.55 21.86a13.49 13.49 0 0 0 1.84 6.31H844L862.15 15c.72-3.53 1.37-6.49 1.22-8.77a10.81 10.81 0 0 0-1.5-5.23Zm-22.1 66.42h11.8L873.07 33l-6.68 34.43" />
      <path
        d="m888 107.06-4.13-25.37a3.37 3.37 0 0 0-3.32-2.82H864a3.36 3.36 0 0 0-3.33 2.94c-.13 1-.63 3.76-1.16 6.64-1.41 7.72-2.41 13.36-2.41 15.65a13.35 13.35 0 0 0 .33 3h-9.38l17.4-91.4.11-.52c.72-3.48 1.34-6.49 1.16-9.11 0-.57-.09-1.12-.17-1.65h19.15l18.49 102.68Zm-14.91-77.41a3.36 3.36 0 0 0-3.3 2.72l-6.67 34.44a3.36 3.36 0 0 0 3.3 4h11.8a3.35 3.35 0 0 0 3.32-3.81l-5.12-34.44a3.37 3.37 0 0 0-3.26-2.87Z"
        fill="#c51f30"
      />
      <path d="M1000.54 116.16c0 5.4 1.15 11.4 3.39 15.55a20 20 0 0 1-2.82.19 20.62 20.62 0 0 1-8.71-1.84c-3.18-1.55-4.88-3.21-8.24-4.33a9.34 9.34 0 0 0-2.88-.39 18.3 18.3 0 0 0-4.63.62A25.19 25.19 0 0 0 979 115c0-8.31-.06-77.06-.06-80.68L959.1 102.9l-21.34-66.29c0 3.21.27 64.36.27 67a15.27 15.27 0 0 0 2 6.78h-17.25V9.27c0-2.87-1.24-5.89-2.36-8.27H947l14.65 46.22L975.59 1h25.14c-.01 5.07-.19 101.4-.19 115.16Z" />
      <path
        d="M999 128.46a16.23 16.23 0 0 1-5.09-1.39c-1.11-.54-2-1.1-3-1.69a25.27 25.27 0 0 0-5.66-2.8 12.08 12.08 0 0 0-3.66-.56 31.49 31.49 0 0 0 .77-7c0-8.23-.09-80-.09-80.7A3.36 3.36 0 0 0 979.4 31a3.87 3.87 0 0 0-.48 0 3.35 3.35 0 0 0-3.22 2.43L959 91.42 941 35.6a3.35 3.35 0 0 0-3.2-2.33 3.23 3.23 0 0 0-.53 0 3.36 3.36 0 0 0-2.83 3.34c0 .64.3 64.39.3 67a14.14 14.14 0 0 0 .43 3.41h-9V9.25a17.21 17.21 0 0 0-.79-4.91h19.17l13.92 43.89a3.36 3.36 0 0 0 3.21 2.34 3.38 3.38 0 0 0 3.2-2.39l13.22-43.8h19.27c0 17.72-.15 99.64-.15 111.82a42.53 42.53 0 0 0 1.78 12.26Z"
        fill="#c51f30"
      />
      <path d="M829.28 1v109.1h-18.14l-21.3-69.24c0 7.23-.11 42.71-.11 50s-.74 12.86 2.71 19.29h-18.15V11.04c0-4.59-.63-6-2.72-10H796l17.63 50.19V1.04Z" />
      <path
        d="m813.66 106.78-20.58-66.89a3.36 3.36 0 0 0-3.21-2.37 2.81 2.81 0 0 0-.5 0 3.35 3.35 0 0 0-2.86 3.32c0 3.62 0 14.3-.06 25s-.05 21.37-.05 25v3.17c-.07 4.3-.14 8.44 1.12 12.77h-9.82V11a20.34 20.34 0 0 0-.86-6.63h16.82l16.86 48a3.36 3.36 0 0 0 3.17 2.24 3.6 3.6 0 0 0 .56 0 3.37 3.37 0 0 0 2.8-3.32V4.38h8.89v102.4Z"
        fill="#c51f30"
      />
      <path d="M1153 1v109.1h-18.11l-21.29-69.24c0 7.23-.12 42.71-.12 50s-.74 12.86 2.71 19.29H1098V11.04c0-4.59-.63-6-2.72-10h24.42l17.64 50.19V1Z" />
      <path
        d="m1137.39 106.78-20.59-66.89a3.35 3.35 0 0 0-3.21-2.37 2.89 2.89 0 0 0-.5 0 3.36 3.36 0 0 0-2.86 3.32v25c0 10.7-.06 21.37-.06 25v3.17c-.07 4.3-.13 8.44 1.12 12.77h-9.79V11a20.07 20.07 0 0 0-.86-6.63h16.82l16.86 48a3.36 3.36 0 0 0 3.17 2.24 3.64 3.64 0 0 0 .57 0 3.37 3.37 0 0 0 2.79-3.32V4.38h8.89v102.4Z"
        fill="#c51f30"
      />
      <path d="M754.73 1v109.1h-18.14l-21.3-69.24c0 7.23-.13 72.37-.1 76a23.86 23.86 0 0 0 2 9.4 9.76 9.76 0 0 0-2-.18 14.05 14.05 0 0 0-4.57.77c-2.36.86-3.71 2.22-5.87 3.38a12.66 12.66 0 0 1-6 1.68 9.47 9.47 0 0 1-1.5-.11 38.55 38.55 0 0 0 2.42-13.89V10.96c0-4.59-.63-6-2.72-10h24.42L739 51.15V1Z" />
      <path
        d="M702.09 127.91a47.43 47.43 0 0 0 1.06-9.93v-107a20.37 20.37 0 0 0-.85-6.63h16.8L736 52.33a3.36 3.36 0 0 0 3.17 2.25 2.75 2.75 0 0 0 .57 0 3.35 3.35 0 0 0 2.8-3.31V4.38h8.88v102.4h-12.31l-20.58-66.89a3.36 3.36 0 0 0-3.21-2.37 2.92 2.92 0 0 0-.51 0 3.36 3.36 0 0 0-2.81 3.36v22.85c0 21.43-.07 50.79 0 53.22a26.1 26.1 0 0 0 .75 6 17.1 17.1 0 0 0-3 .76 19.43 19.43 0 0 0-4.33 2.35c-.66.44-1.28.85-2 1.22-.54.27-.94.47-1.33.63Z"
        fill="#c51f30"
      />
      <path d="M112.89 0a141.88 141.88 0 0 1 23.81 2.23v23.64c-3.72-6.7-7.49-11.28-15.9-11.28-5.64 0-9.89 2.76-12 8.93-2 5.85-2.24 22.6-2.25 38.77 0 14.82-.83 36.21 15.28 36.21 6.26 0 10.52-3.15 14.37-7.67v19.57a74.88 74.88 0 0 1-18.21 2.26c-8.6 0-18.15-2.2-23.53-8.41C86 94.51 83.73 76.93 83.73 62.41 83.74 30.59 87 0 112.89 0Z" />
      <path
        d="M118 109.34c-9.35 0-17-2.64-21-7.25-6.36-7.33-9.86-21.41-9.86-39.65 0-13.32.53-30.12 4.69-42 4-11.61 10.75-17 21.09-17a134 134 0 0 1 20.43 1.66v10.65a18 18 0 0 0-12.54-4.5c-7.31 0-12.71 4-15.21 11.19-2 5.8-2.42 19.56-2.42 39.88v2.05c0 11.49-.07 25.79 7.17 33.05a15.59 15.59 0 0 0 11.48 4.48 19.14 19.14 0 0 0 11-3.36v9.22a71 71 0 0 1-14.83 1.58Z"
        fill="#c51f30"
      />
      <path d="M496.88 0a141.66 141.66 0 0 1 23.8 2.23v23.64c-3.72-6.7-7.49-11.28-15.9-11.28-5.65 0-9.9 2.76-12 8.93-2 5.85-2.24 22.6-2.24 38.77 0 14.82-.83 36.21 15.28 36.21 6.26 0 10.52-3.15 14.36-7.67v19.57a75 75 0 0 1-18.22 2.26c-8.59 0-18.15-2.2-23.53-8.41-8.45-9.74-10.67-27.32-10.67-41.84C467.73 30.59 471 0 496.88 0Z" />
      <path
        d="M502 109.34c-9.35 0-17-2.64-21-7.25-6.36-7.33-9.86-21.41-9.86-39.65 0-13.32.53-30.12 4.68-42 4-11.61 10.76-17 21.1-17a134 134 0 0 1 20.42 1.66v10.65a18 18 0 0 0-12.55-4.5c-7.3 0-12.7 4-15.21 11.19-2 5.8-2.41 19.56-2.41 39.88v2.05c0 11.49-.06 25.79 7.18 33.05a15.58 15.58 0 0 0 11.48 4.48 19.18 19.18 0 0 0 11-3.36v9.22a71.1 71.1 0 0 1-14.83 1.58Z"
        fill="#c51f30"
      />
      <path d="M448.7 2v109.29h-21.48v-100c0-4.25-.93-5.62-2.67-9.3Z" />
      <path
        d="M430.62 108V11.32a17.77 17.77 0 0 0-.85-5.94h15.58V108Z"
        fill="#c51f30"
      />
      <path d="M177.36 2v94.92h10.88c5.64 0 6.57-2.07 10.47-6.32v20.45h-42.82V12.8A18.94 18.94 0 0 0 153.26 2Z" />
      <path
        d="M159.29 107.72v-94.9a23.74 23.74 0 0 0-.91-7.44H174V97a3.36 3.36 0 0 0 3.36 3.37h10.88a13 13 0 0 0 7.11-1.69v9.09Z"
        fill="#c51f30"
      />
      <path d="M338.36 2v21.26C332.61 16 329.63 16 321.22 16c-6.16 0-7.7 2.42-7.7 9.43 0 9.18 7.29 10.55 13.54 14.3 8.56 5.13 13.77 16.75 14.45 29.94 1.11 21.39-1.51 41.24-18.38 41.24h-28.74l-.51-22.74c6.57 6.54 9.63 8.15 16.31 8.15 8.49 0 10.06-10.29 10.06-23.55 0-10.34-5.09-16.82-12.1-19.73-10.51-4.36-16.8-12.89-16.8-27.49C291.36 10 297.69 2 309.79 2Z" />
      <path
        d="m297.71 107.59-.26-11.74c3.8 2.79 7.32 3.88 12.78 3.88 13.42 0 13.42-18.18 13.42-26.92 0-10.78-5.17-19.1-14.19-22.84-9.91-4.11-14.73-12.09-14.73-24.39 0-6.95 1.33-12.17 4-15.53 2.47-3.14 6.1-4.67 11.11-4.67H335v10c-4-2.66-7.9-2.66-13.78-2.66-9.72 0-11.05 6.15-11.05 12.79 0 9.4 6.4 12.64 11.55 15.25 1.21.61 2.47 1.25 3.62 1.94 7.27 4.36 12.2 14.79 12.84 27.23.93 17.7-1.07 28.88-6.1 34.18a11.6 11.6 0 0 1-8.92 3.53Z"
        fill="#c51f30"
      />
      <path d="M404.76 2v21.26C399 16 396 16 387.62 16c-6.16 0-7.69 2.42-7.7 9.43 0 9.18 7.29 10.55 13.54 14.3 8.56 5.13 13.78 16.75 14.46 29.94 1.11 21.39-1.52 41.24-18.39 41.24h-28.74l-.5-22.74c6.56 6.54 9.62 8.15 16.31 8.15 8.49 0 10-10.29 10.06-23.55 0-10.34-5.09-16.82-12.11-19.73-10.51-4.36-16.8-12.89-16.79-27.49C357.76 10 364.1 2 376.19 2Z" />
      <path
        d="m364.12 107.59-.27-11.74c3.8 2.79 7.33 3.88 12.79 3.88 13.41 0 13.41-18.18 13.41-26.92 0-10.78-5.17-19.1-14.18-22.84-9.91-4.11-14.73-12.09-14.73-24.39 0-6.95 1.33-12.17 4-15.53 2.46-3.14 6.09-4.67 11.1-4.67h25.21v10c-4-2.66-7.9-2.66-13.77-2.66-9.72 0-11.06 6.15-11.06 12.79 0 9.4 6.41 12.64 11.55 15.25 1.22.61 2.47 1.25 3.63 1.94C399 47 404 57.44 404.6 69.88c.92 17.7-1.07 28.88-6.11 34.18a11.59 11.59 0 0 1-8.91 3.53Z"
        fill="#c51f30"
      />
      <path d="m257.79 2 19.67 109.38h-23.07l-4.58-28.18h-16.54c-.39 3.11-5.57 31.86-5.93 33.78a24.37 24.37 0 0 0-.07 9.4 7 7 0 0 0-2.17-.33 10.38 10.38 0 0 0-3.36.6c-2.51.84-4.53 2.5-6.88 3.7a12 12 0 0 1-5.5 1.38 11 11 0 0 1-2.07-.2 42.89 42.89 0 0 0 4.65-13.5c1.47-7.77 18.6-97.31 19.5-102 .73-3.53 1.38-6.49 1.22-8.77a10.54 10.54 0 0 0-1.5-5.26Zm-22.11 66.42h11.8L242.36 34l-6.68 34.44" />
      <path
        d="M212.86 127.63a56.31 56.31 0 0 0 2.43-8.94c1.45-7.68 19.28-101.09 19.46-102l.11-.53c.72-3.48 1.34-6.49 1.17-9.1q-.06-.87-.18-1.65H255l18.49 102.67h-16.2l-4.13-25.36a3.36 3.36 0 0 0-3.32-2.82H233.3a3.36 3.36 0 0 0-3.3 2.9c-.37 2.89-5.47 31.31-5.9 33.59a30.59 30.59 0 0 0-.53 6.44 14.48 14.48 0 0 0-2.83.67 24.63 24.63 0 0 0-5 2.55c-.8.49-1.55 1-2.3 1.34a4.9 4.9 0 0 1-.58.24Zm29.52-97a3.36 3.36 0 0 0-3.3 2.73l-6.67 34.44a3.36 3.36 0 0 0 3.3 4h11.8a3.35 3.35 0 0 0 3.32-3.8l-5.12-34.44a3.37 3.37 0 0 0-3.26-2.87Z"
        fill="#c51f30"
      />
      <path d="M47 134v21.26C41.25 148 38.28 148 29.86 148c-6.16 0-7.69 2.41-7.7 9.42 0 9.18 7.29 10.56 13.54 14.31 8.57 5.12 13.78 16.75 14.46 29.94 1.11 21.38-1.51 41.23-18.39 41.23H3l-.5-22.74c6.56 6.55 9.62 8.16 16.31 8.16 8.49 0 10.05-10.3 10.06-23.56 0-10.33-5.09-16.82-12.11-19.72C6.28 180.66 0 172.12 0 157.52 0 142 6.34 134 18.43 134Z" />
      <path
        d="m6.36 239.56-.26-11.74c3.79 2.78 7.32 3.87 12.78 3.87 13.41 0 13.41-18.18 13.41-26.92 0-10.77-5.17-19.1-14.18-22.83-9.91-4.11-14.73-12.09-14.73-24.4 0-6.94 1.33-12.17 4-15.52 2.46-3.14 6.09-4.67 11.1-4.67h25.17v9.94c-4-2.65-7.89-2.65-13.77-2.65-9.72 0-11.06 6.14-11.06 12.79 0 9.39 6.41 12.64 11.56 15.24 1.21.62 2.46 1.25 3.62 1.94 7.27 4.36 12.19 14.8 12.84 27.24.92 17.7-1.07 28.88-6.1 34.18a11.66 11.66 0 0 1-8.92 3.53Z"
        fill="#c51f30"
      />
      <path d="m262.9 134 19.67 109.39H259.5l-4.59-28.18h-16.52c-.41 3.21-3.56 18.53-3.56 21.86a13.5 13.5 0 0 0 1.85 6.32h-18.32L236.55 148c.73-3.53 1.38-6.5 1.22-8.78a10.6 10.6 0 0 0-1.5-5.24Zm-22.1 66.43h11.79L247.48 166l-6.68 34.44" />
      <path
        d="m262.41 240-4.14-25.36a3.35 3.35 0 0 0-3.32-2.82h-16.53a3.37 3.37 0 0 0-3.34 2.93c-.13 1-.63 3.76-1.16 6.65-1.41 7.71-2.41 13.36-2.41 15.65a13.88 13.88 0 0 0 .33 3h-9.38l17.41-91.39.1-.53c.72-3.48 1.35-6.49 1.17-9.1q-.06-.87-.18-1.65h19.14L278.6 240Zm-14.92-77.41a3.37 3.37 0 0 0-3.3 2.73l-6.67 34.44a3.37 3.37 0 0 0 3.3 4h11.8a3.37 3.37 0 0 0 3.33-3.86l-5.13-34.44a3.36 3.36 0 0 0-3.25-2.87Z"
        fill="#c51f30"
      />
      <path d="M176.89 134c7.91 0 15.86.46 23.45 2.76v19.87c-7-6.48-10.23-7.58-19.55-7.58-11 0-14.55 13.66-14.55 27.33l-.24 16.29c0 13.65-1.24 37.92 9.22 37.92a11.18 11.18 0 0 0 3.91-.58l.1-10.33c.1-6.55.21-31.17-2.65-36.91h26.06v59c-7.8 3.33-20.83 4.36-29.15 4.36-23.68 0-30.77-19-30.76-47.12-.01-36.86 5.13-65.01 34.16-65.01Z" />
      <path
        d="M173.52 242.77a26.91 26.91 0 0 1-13.17-3 21.25 21.25 0 0 1-8.19-8.33c-4.08-7.13-6.07-17.74-6.07-32.45 0-21.4 1.83-35.28 5.94-45 4.78-11.35 12.69-16.64 24.88-16.64a84.06 84.06 0 0 1 20.09 2v10.22c-4.53-3-8.73-3.85-16.19-3.85-11.38 0-17.91 11.19-17.91 30.7l-.23 16.28v4c-.1 12.62-.22 28.32 6 34.55a9.22 9.22 0 0 0 6.66 2.8 14.63 14.63 0 0 0 5.07-.78 3.39 3.39 0 0 0 2.19-3.12l.1-10.34c.12-7.73.14-24.35-1.6-33.59h18.28v53.29c-7.3 2.37-18.2 3.26-25.85 3.26Z"
        fill="#c51f30"
      />
      <path d="M123.15 134v99.5c0 4.25.48 7.38 2.55 10.06H88.54c-20.53 0-19.91-8.73-19.9-28.14 0-10.56.22-50.4.22-61 0-7.58.73-13.67-2.76-20.45h24.11v86.48c0 7.12 2.36 8 8.21 8h3.19v-94.52Z" />
      <path
        d="M88.58 240.2c-10.42 0-13.19-2.38-14.52-4.71-2-3.57-2-9.8-2-18.42v-1.65c0-5.28 0-18.09.1-30.48s.11-25.22.11-30.51v-3.25c.07-4.68.13-9.16-1.22-13.83h15.81v83.12c0 9.5 4.43 11.4 11.57 11.4h3.18a3.36 3.36 0 0 0 3.37-3.36v-91.16h14.82v96.14a27.78 27.78 0 0 0 .66 6.71Z"
        fill="#c51f30"
      />
      <g
        clipPath="url(#a)"
        style={{
          isolation: "isolate",
        }}
      >
        <path
          d="m327.19 187.68 5.62 5.62a7.16 7.16 0 0 0-1-.82l-5.62-5.62a7.16 7.16 0 0 1 1 .82"
          fill="#74919d"
        />
      </g>
      <g
        clipPath="url(#b)"
        style={{
          isolation: "isolate",
        }}
      >
        <path
          d="m326.26 186.75 5.62 5.62a7.77 7.77 0 0 0-1-.82l-5.62-5.62a7.77 7.77 0 0 1 1 .82"
          fill="#5e7079"
        />
      </g>
      <g
        clipPath="url(#c)"
        style={{
          isolation: "isolate",
        }}
      >
        <path
          d="m324.41 184.89 5.61 5.62a7.11 7.11 0 0 0-1-.81l-5.62-5.62a7.86 7.86 0 0 1 1 .81"
          fill="#495b64"
        />
      </g>
      <path d="M359.11 247.27a38.45 38.45 0 0 0 6.64 16.13 20.89 20.89 0 0 1-2.85.2 22.44 22.44 0 0 1-8.94-1.92c-3.68-1.6-4.73-3.32-8.53-4.49a11.79 11.79 0 0 0-3.33-.4 21.41 21.41 0 0 0-5.27.64 28.45 28.45 0 0 0 1.28-11.43 384.66 384.66 0 0 0-6.3-42.44c-1.87-9-4.8-17-8.37-19.5v58.91H302v-99.61c0-4-.82-6-2.67-9.42h33c15.59 0 21.94 7.3 22.49 23.93.28 8.37-2.33 18-11.78 21.13 4 3.44 6.64 13.08 8.61 22.55 3.88 18.45 5.74 34.23 7.46 45.72Zm-35.23-79c6.85 0 9.29-2.63 8.88-9.88-.38-6.77-2.21-10.8-7.36-10.8h-1.95v20.71h.43" />
      <path
        d="M359.8 260a19.58 19.58 0 0 1-4.45-1.35 20.61 20.61 0 0 1-3.28-1.85 20.23 20.23 0 0 0-5.61-2.78 15.11 15.11 0 0 0-4.32-.54h-.88a31.35 31.35 0 0 0 .24-7.74 391.16 391.16 0 0 0-6.37-42.81c-1.64-7.9-4.66-18.06-9.75-21.59a3.33 3.33 0 0 0-1.92-.6 3.45 3.45 0 0 0-1.56.38 3.38 3.38 0 0 0-1.8 3v55.56h-14.72V143.4a19.74 19.74 0 0 0-.83-6.06h27.81c13.45 0 18.64 5.61 19.15 20.68.42 12.61-5.76 16.59-9.48 17.84a3.36 3.36 0 0 0-1.14 5.72c2.6 2.26 5.14 9.23 7.53 20.7 3 14.37 4.8 27.17 6.25 37.46.41 2.88.79 5.57 1.16 8.07A41.55 41.55 0 0 0 359.8 260Zm-36.34-115.7a3.36 3.36 0 0 0-3.36 3.36v20.68a3.36 3.36 0 0 0 3.31 3.36h.5c2.79 0 6.82-.33 9.52-3.19s2.87-7.25 2.7-10.24c-.52-9.27-4.13-14-10.72-14Z"
        fill="#c51f30"
      />
      <path d="M1050.48 134v21.26c-5.74-7.24-8.72-7.24-17.13-7.24-6.16 0-7.7 2.41-7.7 9.42 0 9.18 7.29 10.56 13.54 14.31 8.56 5.12 13.77 16.75 14.46 29.94 1.11 21.38-1.52 41.23-18.39 41.23h-28.74l-.51-22.74c6.57 6.55 9.63 8.16 16.32 8.16 8.49 0 10-10.3 10-23.56 0-10.33-5.09-16.82-12.1-19.72-10.51-4.36-16.8-12.9-16.79-27.5 0-15.51 6.34-23.56 18.43-23.56Z" />
      <path
        d="m1009.84 239.56-.27-11.74c3.8 2.78 7.32 3.87 12.79 3.87 13.41 0 13.41-18.18 13.41-26.92 0-10.77-5.17-19.1-14.18-22.83-9.92-4.11-14.74-12.09-14.74-24.4 0-6.94 1.34-12.17 4-15.52 2.46-3.14 6.09-4.67 11.1-4.67h25.21v9.94c-4-2.65-7.9-2.65-13.78-2.65-9.71 0-11 6.14-11 12.79 0 9.39 6.4 12.64 11.55 15.24 1.22.62 2.47 1.25 3.63 1.94 7.27 4.36 12.19 14.8 12.84 27.24.92 17.7-1.08 28.88-6.11 34.18a11.63 11.63 0 0 1-8.92 3.53Z"
        fill="#c51f30"
      />
      <path d="m972 134 19.67 109.39h-23.1L964 215.17h-16.55c-.41 3.21-3.55 18.53-3.55 21.86a13.5 13.5 0 0 0 1.85 6.32h-18.32L945.62 148c.73-3.53 1.37-6.5 1.22-8.78a10.6 10.6 0 0 0-1.5-5.24Zm-22.11 66.43h11.8L956.54 166l-6.68 34.44" />
      <path
        d="m971.47 240-4.13-25.36a3.36 3.36 0 0 0-3.32-2.82h-16.53a3.37 3.37 0 0 0-3.34 2.93c-.13 1-.63 3.76-1.16 6.65-1.41 7.71-2.41 13.36-2.41 15.65a13.26 13.26 0 0 0 .33 3h-9.38l17.4-91.39.11-.53c.72-3.48 1.34-6.49 1.17-9.1q-.06-.87-.18-1.65h19.14L987.66 240Zm-14.91-77.41a3.37 3.37 0 0 0-3.3 2.73l-6.67 34.44a3.37 3.37 0 0 0 3.3 4h11.8a3.37 3.37 0 0 0 3.33-3.86l-5.13-34.44a3.37 3.37 0 0 0-3.25-2.87Z"
        fill="#c51f30"
      />
      <path d="M1194.35 134v20.22c-5.7-6.27-6.89-6.55-13.62-6.55h-2.18c-3.7 0-7.29.11-10.88.11v18.18h22.88v14.93h-22.88v48.32h16.41c5.64 0 6.57-2.07 10.47-6.32v20.44h-48.34v-99.11c0-4.94-.51-6.08-2.66-10.22Z" />
      <path
        d="M1149.6 240v-95.8a21.3 21.3 0 0 0-.82-6.86H1191v9.11c-2.85-1.92-5.45-2.16-10.25-2.16h-2.2c-1.88 0-3.76 0-5.57.06s-3.55.05-5.31.05a3.37 3.37 0 0 0-3.36 3.37v18.17a3.37 3.37 0 0 0 3.36 3.37h19.53v8.2h-19.53a3.37 3.37 0 0 0-3.36 3.37v48.32a3.36 3.36 0 0 0 3.36 3.36h16.42a13 13 0 0 0 7.11-1.68V240Z"
        fill="#c51f30"
      />
      <path d="M1124.46 134v25.2c-3.81-6.51-6.62-9.8-11.8-9.8h-4.31v85.09c0 3.56 1 6 2.57 9h-24.12v-94h-4.41c-4.52 0-7.82 3.48-11 9.57V134Z" />
      <path
        d="M1090.18 240.08v-90.7a3.37 3.37 0 0 0-3.37-3.36h-4.41a11.79 11.79 0 0 0-7.67 2.86v-11.53h46.39v11.78a12.33 12.33 0 0 0-8.44-3.11h-4.31a3.37 3.37 0 0 0-3.37 3.36v85.1a18.91 18.91 0 0 0 .83 5.6Z"
        fill="#c51f30"
      />
      <path d="M915 134v25.2c-3.81-6.51-6.62-9.8-11.8-9.8h-4.31v99.44c0 5.4 1.15 11.39 3.39 15.54a20 20 0 0 1-2.82.2 20.63 20.63 0 0 1-8.71-1.85c-3.17-1.54-4.88-3.21-8.24-4.34a9.66 9.66 0 0 0-2.88-.38 18.3 18.3 0 0 0-4.63.62 25.33 25.33 0 0 0 2.32-11v-98.22h-4.42c-4.52 0-7.82 3.48-11 9.57V134Z" />
      <path
        d="M897.31 261.08a16.23 16.23 0 0 1-5.09-1.38 33.52 33.52 0 0 1-3-1.69 25.71 25.71 0 0 0-5.66-2.81 12.49 12.49 0 0 0-3.67-.55 31.56 31.56 0 0 0 .77-7V149.38a3.35 3.35 0 0 0-3.36-3.36h-4.41a11.86 11.86 0 0 0-7.68 2.85v-11.53h46.39v11.78a12.35 12.35 0 0 0-8.44-3.1h-4.31a3.35 3.35 0 0 0-3.36 3.36v99.45a42.07 42.07 0 0 0 1.82 12.25Z"
        fill="#c51f30"
      />
    </svg>
  )
}

export default TaglineDesktop
