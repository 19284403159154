import * as React from "react"

function BaconBrittleMobile(props) {
  return (
    <svg
      style={{
        postion: `absolute`,
      }}
      xmlns="http://www.w3.org/2000/svg"
      // width={201.23}
      // height={41.37}
      viewBox="0 0 201.23 41.37"
      {...props}
    >
      <path
        d="M7.32 19.8c-4.34 0-6.55-2.11-6.73-6.37l3.71.11a2.79 2.79 0 0 0 3 2.74c1.45 0 2-.8 2-1.58 0-1.3-1.42-1.79-2.92-2.31-2.37-.82-5.32-1.88-5.32-6.12C1.06 2.73 3.28.53 6.59.78c3.73.27 5.83 2.35 6.24 6.2L9.24 6.8a2.65 2.65 0 0 0-2.47-2.51c-1.35-.08-2.07.55-2.07 1.73s1.44 1.82 3 2.33c2.45.82 5.5 1.81 5.49 6-.03 3.28-2.37 5.46-5.87 5.45Z"
        fill="#fff"
      />
      <path
        d="M6.51 1.35c3.3.23 5.12 1.84 5.65 5l-2.44-.08a3.19 3.19 0 0 0-3-2.54 2.2 2.2 0 0 0-2.6 2.26c0 1.75 1.8 2.36 3.38 2.89 2.5.84 5.08 1.67 5.08 5.46 0 3-2.06 4.91-5.26 4.89-3.8 0-5.76-1.69-6.1-5.2l2.6.1a3.27 3.27 0 0 0 3.44 2.74c1.76 0 2.55-1.05 2.55-2.13 0-1.7-1.78-2.31-3.34-2.86-2.41-.87-4.91-1.75-4.91-5.58 0-3.23 1.94-5.17 4.95-4.95m0-1.14C2.95-.07.42 2.25.42 6.24c0 7.39 8.24 6 8.24 8.44 0 .61-.42 1.05-1.38 1-1.5 0-2.29-.74-2.44-2.72L0 12.83c0 5.25 2.77 7.52 7.32 7.53 3.76 0 6.42-2.33 6.43-6 0-7.23-8.47-5.87-8.47-8.32 0-.85.5-1.25 1.49-1.19.77 0 1.92.56 1.92 2.48l4.73.24c-.24-3.66-1.83-7-6.95-7.36Z"
        fill="#c51f30"
      />
      <path
        d="M24.76 19.67q-2.57-6.93-5.15-14.06v14H16.2V1.69c1.78.12 3.56.22 5.34.33q2.63 6.84 5.24 13.48V2.32l3.41.19v17.2Z"
        fill="#fff"
      />
      <path
        d="M29.61 3.01v16.17h-4.43q-1.95-5.22-3.89-10.57c-.74-2-1.48-4.09-2.22-6.15l2.07.12q2 5.19 4 10.26 1.13 2.88 2.25 5.72V2.89l2.25.12m-10.61-.59v16.71h-2.25V2.28l2.25.14m11.75-.41-4.58-.26v10.71q-2.13-5.41-4.25-11l-6.34-.33v19h4.58V8.88q2.09 5.75 4.16 11.33h6.43V2.01Z"
        fill="#c51f30"
      />
      <path d="M34.33 19.74v-17l4.16.2v16.83Z" fill="#fff" />
      <path
        d="M37.91 3.41v15.83h-3V3.27l3 .14m1.16-1c-1.77-.09-3.55-.17-5.32-.27v18.1h5.32V2.43Z"
        fill="#c51f30"
      />
      <path
        d="M49.25 20.06c-4.83 0-7.28-2.87-7.28-8.51s2.63-8.6 7.21-8.42c3.82.15 5.22 1.88 6.18 4.81l-3.68 1.19a2.79 2.79 0 0 0-2.61-2.46c-2.59-.09-2.89 2.77-2.89 4.92 0 3.11 1.12 4.9 3.09 4.92a2.31 2.31 0 0 0 2.29-1.76l3.63 1.51c-1.09 2.42-2.49 3.81-5.94 3.8Z"
        fill="#fff"
      />
      <path
        d="M49.18 3.65c3 .11 4.43 1.11 5.47 4l-2.58.83a3.18 3.18 0 0 0-3-2.3c-3.47-.12-3.48 4.05-3.48 5.42 0 4.88 2.57 5.42 3.68 5.44a2.78 2.78 0 0 0 2.61-1.58l2.54 1.05c-1.14 2.27-2.54 3.06-5.19 3-1.66 0-6.7 0-6.7-8 0-2.47.64-8.17 6.63-7.92m0-1c-5.11-.18-7.74 3.39-7.74 8.95 0 5.34 2.15 9 7.86 9 3.87 0 5.46-1.65 6.67-4.55l-4.74-2c-.35 1.29-.88 2-1.91 2-1.68 0-2.51-1.76-2.51-4.39 0-3.33.86-4.48 2.31-4.44 1.25 0 1.89 1 2.16 2.65l4.82-1.56c-1-3.43-2.58-5.46-6.87-5.64Z"
        fill="#c51f30"
      />
      <path
        d="M68.18 19.9q-2.61-4.05-5.23-8.17 2.47-3.91 4.88-7.76l4.71.1c-1.73 2.55-3.47 5.1-5.2 7.68q2.85 4.11 5.71 8.17Zm-9.9 0V3.7l4.14.12v16.06Z"
        fill="#fff"
      />
      <path
        d="M71.55 4.54Q69.27 7.88 67 11.26l-.33.48.34.5q2.5 3.62 5 7.18h-3.5c-1.49-2.31-3-4.63-4.48-7l-.44-.71.45-.73c1.38-2.17 2.77-4.35 4.15-6.52l3.36.08m-9.68-.24v15.08h-3V4.21l3 .09m11.67-.69-6.06-.14-4.45 7.06V3.34l-5.33-.16v17.18h5.33V12.9q2.4 3.77 4.81 7.49h6.23q-3-4.29-6.06-8.65 2.76-4.09 5.53-8.14Z"
        fill="#c51f30"
      />
      <path
        d="M75.97 19.92V4.13c3.76.07 7.53.12 11.29.16v3.3l-7.12-.06v2.91h6.46v2.85h-6.46v3.31h7.34v3.3Z"
        fill="#fff"
      />
      <path
        d="M86.67 4.78v2.35l-6-.07h-1.11v3.87h6.45v1.89h-6.45v4.31h7.34v2.34H76.55V4.64c3.38.06 6.75.1 10.12.14m1.17-1q-6.22-.02-12.42-.14v16.77h12.67v-4.28h-7.34v-2.34h6.46v-3.78l-6.46-.05V8.02l7.09.11v-4.3Z"
        fill="#c51f30"
      />
      <path
        d="m100.21 19.95-2.39-3.42c-.66-.95-1.08-1.57-2.09-1.57h-.94v5h-4.17V4.33h7.09c3.48 0 5.57 1.91 5.56 5.13 0 2.86-1.34 3.87-2.51 4.44l-.88.42.9.41a6.32 6.32 0 0 1 1.41 1.54q1.23 1.86 2.57 3.68Zm-5.42-8h1.8c1.13 0 2.47-.37 2.47-2.15a2.17 2.17 0 0 0-2.41-2.14h-1.86Z"
        fill="#fff"
      />
      <path
        d="M97.71 4.84c3.17 0 5 1.7 5 4.66 0 2.6-1.12 3.49-2.22 4l-1.79.84 1.72.79a6.57 6.57 0 0 1 1.22 1.39l2.07 2.91h-3.21l-2.23-3.21c-.66-.95-1.23-1.77-2.58-1.78h-1.48v5h-3V4.82h6.5m-3.5 7.58h2.38c1.94 0 3-1 3-2.63a2.7 2.7 0 0 0-3-2.62h-2.38v5.26m3.5-8.53h-7.67v16.55h5.38v-5h.36c.68 0 1 .43 1.58 1.32l2.56 3.67h5.9l-3.08-4.36a6.47 6.47 0 0 0-1.63-1.72c1.43-.69 2.8-1.9 2.8-4.85 0-3.47-2.32-5.61-6.15-5.61Zm-2.29 7.58V8.13h1.28a1.64 1.64 0 0 1 1.83 1.66c0 1-.46 1.68-1.89 1.67Z"
        fill="#c51f30"
      />
      <path
        d="M107.13 19.95V4.35h6.16c4.43 0 7.41 3.08 7.41 7.81s-3 7.82-7.41 7.82Zm4.16-3.3h1.84c2 0 3.36-1.79 3.36-4.54s-1.32-4.52-3.36-4.5h-1.84Z"
        fill="#fff"
      />
      <path
        d="M113.29 4.79c4.08 0 6.83 2.89 6.83 7.33s-2.75 7.34-6.83 7.34h-5.58V4.83h5.58m-2.58 12.3h2.42c2.4 0 3.94-2 3.94-5s-1.54-5-3.94-5h-2.42v10m2.58-13.3h-6.75v16.6h6.75c4.78 0 8-3.35 8-8.32s-3.22-8.34-8-8.28Zm-1.41 12.3v-8h1.25c1.7 0 2.78 1.57 2.78 4s-1.08 4-2.78 4Z"
        fill="#c51f30"
      />
      <path
        d="M130.69 20.13c-4.77 0-7.5-2.94-7.5-8.09s2.73-8.14 7.5-8.25 7.5 2.81 7.5 8.06-2.77 8.28-7.5 8.28Zm0-12.92c-1.5 0-3.29.88-3.29 4.79s1.79 4.72 3.29 4.71 3.29-.85 3.29-4.8-1.78-4.72-3.29-4.69Z"
        fill="#fff"
      />
      <path
        d="M130.69 4.29c2.59-.06 6.92.83 6.92 7.58s-4.33 7.77-6.92 7.78-6.92-1-6.92-7.6 4.34-7.7 6.92-7.76m0 12.92c1.77 0 3.88-.94 3.88-5.29s-2.15-5.22-3.88-5.19-3.87 1-3.87 5.28 2.1 5.21 3.87 5.2m0-13.9c-5.37.13-8.08 3.62-8.08 8.75s2.71 8.57 8.08 8.57 8.09-3.54 8.09-8.78-2.71-8.67-8.09-8.54Zm0 12.92c-1.94 0-2.71-1.66-2.71-4.23s.77-4.26 2.71-4.29 2.73 1.64 2.73 4.22-.77 4.29-2.71 4.3Z"
        fill="#c51f30"
      />
      <path
        d="M148.18 20.13c-4.77 0-7.5-3-7.5-8.26s2.73-8.35 7.5-8.54 7.5 2.81 7.5 8.24-2.73 8.5-7.5 8.56Zm0-13.28c-1.5 0-3.29.94-3.29 4.95s1.79 4.83 3.29 4.81 3.29-.88 3.29-4.95-1.79-4.91-3.29-4.86Z"
        fill="#fff"
      />
      <path
        d="M148.18 3.79c2.58-.09 6.92.76 6.92 7.74s-4.34 8-6.92 8-6.92-1-6.92-7.76 4.34-7.93 6.92-8m0 13.27c1.77 0 3.87-1 3.87-5.46s-2.1-5.36-3.87-5.3-3.87 1-3.87 5.46 2.1 5.32 3.87 5.3m0-14.28c-5.37.21-8.08 3.82-8.08 9s2.71 8.76 8.08 8.75 8.08-3.66 8.08-9.08-2.71-8.93-8.08-8.72Zm0 13.28c-1.94 0-2.71-1.69-2.71-4.33s.77-4.37 2.71-4.43 2.71 1.66 2.71 4.32-.77 4.42-2.71 4.44Z"
        fill="#c51f30"
      />
      <path
        d="M158.83 19.79V3.13l6.15-.29c4.44-.26 7.44 3.07 7.44 8.29s-3 8.62-7.41 8.63Zm4.16-3.58h1.84c2 0 3.35-2 3.35-5s-1.32-4.88-3.35-4.8l-1.84.07Z"
        fill="#fff"
      />
      <path
        d="M164.98 3.33c4.09-.2 6.83 2.92 6.83 7.81s-2.74 8.07-6.83 8.09h-5.57V3.59l5.57-.26m-2.56 13.4h2.42c2.39 0 3.93-2.2 3.93-5.52s-1.54-5.41-3.93-5.31h-2.42v10.83m2.57-14.44-6.74.32V20.3h6.74c4.78 0 8-3.69 8-9.17s-3.21-9.06-8-8.81Zm-1.41 13.39V6.96h1.26c1.69-.06 2.77 1.63 2.77 4.3s-1.08 4.42-2.77 4.45h-1.26Z"
        fill="#c51f30"
      />
      <path d="M175.53 19.68V2.26l4.16-.24v13.91l6.67-.13v3.8Z" fill="#fff" />
      <path
        d="M179.11 2.6v13.88h1.16l5.51-.1v2.69l-9.67.09V2.77l3-.17m1.16-1.16-5.32.32v18.46l12-.07v-4.92l-6.67.14V1.44Z"
        fill="#c51f30"
      />
      <path
        d="M189.17 19.57V1.42q5.64-.37 11.26-.79v4q-3.54.23-7.1.42v3.4c2.15-.09 4.3-.2 6.44-.3v3.43l-6.44.23v3.87l7.32-.16v4Z"
        fill="#fff"
      />
      <path
        d="M199.85 1.25v2.81l-5.94.36-1.16.07v4.52l1.16-.05 5.28-.24v2.27l-5.28.2h-1.16v5h1.16l6.16-.13v2.81l-10.32.12V1.94q5.05-.33 10.1-.69M201.01 0q-6.21.48-12.42.9v19.23q6.33 0 12.64-.09v-5.15c-2.44.06-4.88.13-7.32.18v-2.75l6.44-.22V7.51l-6.44.32V5.56l7.1-.41V0Z"
        fill="#c51f30"
      />
      <path
        d="M61.22 40.74v-12.7h-4.25v-3.38h12.67v3.35h-4.25v12.61Z"
        fill="#fff"
      />
      <path
        d="M69.06 25.13v2.38h-4.25v12.62l-3 .09V27.54h-4.25v-2.41h11.5m1.17-1H56.42v4.42l4.25-.05v12.75l5.3-.12V28.49h4.26v-4.36Z"
        fill="#c51f30"
      />
      <path
        d="M78.64 40.56c-4.77.09-7.51-2.82-7.5-8s2.73-8.18 7.5-8.18 7.51 2.93 7.5 8-2.72 8.1-7.5 8.18Zm0-12.8c-1.5 0-3.29.83-3.29 4.74s1.79 4.7 3.29 4.67 3.29-.85 3.29-4.73-1.79-4.69-3.29-4.68Z"
        fill="#fff"
      />
      <path
        d="M78.64 24.85c2.58 0 6.92 1 6.92 7.56s-4.34 7.62-6.92 7.72-6.92-.86-6.92-7.54 4.34-7.68 6.92-7.69m0 12.81c1.77 0 3.87-1 3.87-5.23s-2.1-5.16-3.87-5.16-3.88.93-3.87 5.24 2.1 5.17 3.87 5.15m0-13.78c-5.37 0-8.09 3.5-8.09 8.68s2.71 8.59 8.09 8.49 8.09-3.55 8.09-8.65-2.71-8.52-8.09-8.52Zm0 12.81c-1.94 0-2.71-1.64-2.71-4.2s.77-4.24 2.71-4.25 2.71 1.66 2.71 4.2-.77 4.22-2.71 4.25Z"
        fill="#c51f30"
      />
      <path
        d="M89.29 40.2V24.58h11.07v3.29h-6.94v3.55h6.23v2.84h-6.23v5.87Z"
        fill="#fff"
      />
      <path
        d="M99.77 25.05v2.33h-6.89v4.51h6.23v1.88h-6.23v5.91h-3V25.06h9.9m1.17-1H88.71v16.63h5.33v-5.95h6.24v-3.81h-6.24v-2.58h6.9v-4.22Z"
        fill="#c51f30"
      />
      <path
        d="M103.42 40.13V24.57h11v3.3h-6.9v3.56h6.24v2.85h-6.24v5.85Z"
        fill="#fff"
      />
      <path
        d="M113.87 25.06v2.34h-6.89v4.52h6.23v1.89h-6.23v5.91h-3V25.05h9.9m1.17-1h-12.24v16.59h5.33v-5.89h6.24v-3.78h-6.24v-2.62h6.9v-4.22Z"
        fill="#c51f30"
      />
      <path
        d="M117.49 40.26V24.59h11.29v3.34h-7.12v2.91l6.46.06v2.88l-6.46-.07v3.31l7.34.11v3.35c-3.84-.1-7.67-.17-11.51-.22Z"
        fill="#fff"
      />
      <path
        d="M128.2 25.13v2.36h-7.16v3.87h6.45v1.91l-5.29-.06h-1.16v4.28h1.16l6.18.09v2.37q-5.18-.11-10.34-.17V25.07h10.12m1.16-1h-12.45v16.67q6.33.07 12.67.22v-4.32c-2.45 0-4.89-.08-7.34-.11v-2.35l6.46.08v-3.87l-6.46-.05V28.4h7.12v-4.27Z"
        fill="#c51f30"
      />
      <path
        d="M132.14 40.53V24.62h11.28v3.41l-7.11-.06v3l6.45.09V34l-6.45-.11v3.37l7.34.17v3.42c-3.84-.12-7.67-.23-11.51-.32Z"
        fill="#fff"
      />
      <path
        d="M142.84 25.13v2.41l-5.95-.05h-1.16v3.94h1.16l5.29.08v1.95l-5.29-.1h-1.16v4.36h1.16l6.17.16v2.45c-3.44-.1-6.89-.2-10.33-.28V25.13l10.11.05m1.17-1h-12.45V41q6.33.15 12.67.37v-4.42l-7.34-.17v-2.39l6.46.12v-3.94l-6.46-.09v-2l7.12.07v-4.42Z"
        fill="#c51f30"
      />
    </svg>
  )
}

export default BaconBrittleMobile
